<template>
  <div>
    <v-row>
      <v-col
        md="12"
        cols="12"
      >
        <v-card>
          <v-img
            class="white--text align-center"
            src="@/assets/images/header/csgo-series-dark2.png"
            height="100%"
          >
            <v-card-title class="pt-0 px-0 pl-5 tournament-title">
              BGL CS:GO Series - 1
            </v-card-title>
            <div class="pl-4 pr-4 registered-teams">
              <div class="d-flex justify-space-between align-center">
                <span class="font-weight-medium registration-badge">
                  12 / 32 Teams registered
                </span>
              </div>
            </div>
            <v-col
              cols="12"
              md="12"
            >
              <v-btn
                color="mainbtn"
                class="ml-2 mr-1"
              >
                Event Page
              </v-btn>
              <v-btn
                color="mainbtn"
                class="ml-1 mr-1"
              >
                Pay
              </v-btn>
              <v-btn
                color="mainbtn"
                class="ml-1 mr-1"
              >
                Rulebook
              </v-btn>
            </v-col>
            <v-row>
              <v-col
                md="2"
                sm="2"
                cols="12"
                class="align-self-start mt-8 ml-2"
              >
                <div class="text-left ml-4">
                  Head Sponsor
                </div>
                <v-img
                  src="@/assets/images/partners/faceit.png"
                  height="100px"
                >
                </v-img>
              </v-col>
              <v-col
                md="2"
                sm="2"
                cols="12"
                class="align-self-start mt-8 ml-2"
              >
                <div class="text-left">
                  Sponsors
                </div>
                <v-img
                  src="@/assets/images/partners/fg-logo.png"
                  height="100px"
                  width="100px"
                >
                </v-img>
              </v-col>
            </v-row>
          </v-img>
        </v-card>
        <v-card>
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-card-text class="position-relative">
                <div class="d-flex justify-space-between flex-wrap pt-0">
                  <div class="me-2 mb-2">
                    <v-card-title class="mt-0 px-0">
                      Event Information
                    </v-card-title>
                    <v-card-subtitle class="text-m pa-0 pl-2 pt-0">
                      <span class="bold">Sign-up starts:</span>
                      <br>
                      <span class="bold">Sign-up ends:</span>
                      <br>
                      <span class="bold">Starts:</span>
                      <br>
                      <span class="bold">Team format:</span>
                      <br>
                      <span class="bold">Buy-In per Team:</span>
                    </v-card-subtitle>
                    <v-card-title class="pt-4 px-0">
                      Tournament format
                    </v-card-title>
                    <v-card-subtitle class="text-m pa-0 pl-2">
                      <span class="bold">01</span> - GROUPS (This is Demo)
                      <br>
                      <span class="bold">02</span> - SHOWDOWN STAGE (This is Demo)
                      <br>
                      <span class="bold"> 03</span> - FINALS STAGE (This is Demo)
                    </v-card-subtitle>
                    <v-card-title class="pt-4 px-0">
                      Prize distribution
                    </v-card-title>
                    <v-card-subtitle class="text-m pa-0 pl-2">
                      <span class="bold">1st</span> - x amount €
                      <br>
                      <span class="bold">2nd</span> - x amount €
                      <br>
                      <span class="bold">3rd</span> - x amount €
                    </v-card-subtitle>
                  </div>
                </div>
              </v-card-text>
            </v-col>

            <v-col
              cols="12"
              md="2"
            >
              <div class="text-left ml-4">
                Registered teams 15 of 32
              </div>
              <template>
                <v-expansion-panels focusable>
                  <v-expansion-panel
                    v-for="item in 8"
                    :key="item"
                  >
                    <v-expansion-panel-header class="panel-header">
                      {{ item }}.
                      <v-img
                        width="20"
                        height="100%"
                        src="/flags/ee.png"
                        class="user-flag ml-1 mr-1"
                      >
                      </v-img>

                      Teamname
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                      Ice cream I love chocolate cake I love liquorice chocolate bar candy canes marzipan. Marshmallow cotton candy dessert bonbon tiramisu sweet. Wafer jelly jelly beans ice cream candy. Candy candy chocolate cake ice cream.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>
            </v-col>
            <v-col
              cols="12"
              md="2"
              class="mt-6"
            >
              <template>
                <v-expansion-panels focusable>
                  <v-expansion-panel
                    v-for="item in 8"
                    :key="item"
                  >
                    <v-expansion-panel-header class="panel-header">
                      {{ item }}.
                      <v-img
                        width="20"
                        height="100%"
                        src="/flags/ee.png"
                        class="user-flag ml-1 mr-1"
                      >
                      </v-img>

                      Teamname
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                      Ice cream I love chocolate cake I love liquorice chocolate bar candy canes marzipan. Marshmallow cotton candy dessert bonbon tiramisu sweet. Wafer jelly jelly beans ice cream candy. Candy candy chocolate cake ice cream.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>
            </v-col>
            <v-col
              cols="12"
              md="2"
              class="mt-6"
            >
              <template>
                <v-expansion-panels focusable>
                  <v-expansion-panel
                    v-for="item in 8"
                    :key="item"
                  >
                    <v-expansion-panel-header class="panel-header">
                      {{ item }}.
                      <v-img
                        width="20"
                        height="100%"
                        src="/flags/ee.png"
                        class="user-flag ml-1 mr-1"
                      >
                      </v-img>

                      Teamname
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                      <v-img
                        width="20"
                        height="100%"
                        src="/flags/ee.png"
                        class="user-flag ml-1 mr-1"
                      >
                      </v-img> AUTRIII<br>
                      <v-img
                        width="20"
                        height="100%"
                        src="/flags/ee.png"
                        class="user-flag ml-1 mr-1"
                      >
                      </v-img> AUTRIII<br>
                      <v-img
                        width="20"
                        height="100%"
                        src="/flags/ee.png"
                        class="user-flag ml-1 mr-1"
                      >
                      </v-img> AUTRIII<br>
                      <v-img
                        width="20"
                        height="100%"
                        src="/flags/ee.png"
                        class="user-flag ml-1 mr-1"
                      >
                      </v-img> AUTRIII<br>
                      <v-img
                        width="20"
                        height="100%"
                        src="/flags/ee.png"
                        class="user-flag ml-1 mr-1"
                      >
                      </v-img> AUTRIII<br>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>
            </v-col>
            <v-col
              cols="12"
              md="2"
              class="mt-6"
            >
              <template>
                <v-expansion-panels focusable>
                  <v-expansion-panel
                    v-for="item in 8"
                    :key="item"
                  >
                    <v-expansion-panel-header class="panel-header">
                      {{ item }}.
                      <v-img
                        width="20"
                        height="100%"
                        src="/flags/ee.png"
                        class="user-flag ml-1 mr-1"
                      >
                      </v-img>

                      Teamname
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                      Ice cream I love chocolate cake I love liquorice chocolate bar candy canes marzipan. Marshmallow cotton candy dessert bonbon tiramisu sweet. Wafer jelly jelly beans ice cream candy. Candy candy chocolate cake ice cream.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAlertCircleOutline,
  mdiCheck,
  mdiAlertOutline,
  mdiBrightness1,
  mdiTrophy,
  mdiTwitch,
  mdiChevronUp,
  mdiChevronDown,
  mdiAlertOctagon,
} from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiAlertCircleOutline,
        mdiCheck,
        mdiAlertOutline,
        mdiBrightness1,
        mdiTrophy,
        mdiTwitch,
        mdiChevronUp,
        mdiChevronDown,
        mdiAlertOctagon,
      },
    }
  },
}
</script>
